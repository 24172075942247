'use client';
import { useEffect } from 'react';
import { nextBrowser } from '.';

const __useOnBlur = (ref: React.RefObject<any>[], callback: () => void) => {
    const dep = ref.map((item) => item.current);
    const handleClick = (e: { target: any }) => {
        if (
            ref.length &&
            !ref.map((item) => !!item.current).includes(false) &&
            !ref?.map((item) => item.current.contains(e.target)).includes(true)
        ) {
            callback();
        }
    };

    useEffect(() => {
        nextBrowser.document?.addEventListener('click', handleClick);

        return () => {
            nextBrowser.document?.removeEventListener('click', handleClick);
        };
    }, [dep]);
};

export default __useOnBlur;
