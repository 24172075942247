'use client';
import { JWTTokenType } from 'types';

const authToken = {
    key: 'TEHRANTO_AUTH_TOKEN',
    get: function (): JWTTokenType | null {
        const data = localStorage.getItem(authToken.key);
        if (data) {
            return JSON.parse(data);
        }
        return null;
    },
    set: (value: JWTTokenType) => {
        localStorage.setItem(authToken.key, JSON.stringify(value));
    },
    remove: () => {
        localStorage.removeItem(authToken.key);
    },
};

export const searchList = {
    key: 'TEHRANTO_SERVICES_SEARCH',
    add: (value: string) => {
        const data = searchList.get();
        if (!data.includes(value)) data.push(value);
        searchList.set(data);
    },
    get: (): string[] => {
        const data = localStorage.getItem(searchList.key);
        if (data) {
            try {
                const list: string[] = JSON.parse(data);
                return list;
            } catch (err) {
                return [];
            }
        } else {
            return [];
        }
    },
    set: (values: string[]) => {
        localStorage.setItem(searchList.key, JSON.stringify(values));
    },
    remove: () => {
        localStorage.removeItem(searchList.key);
    },
};
export const __AppStorage = {
    authToken,
    searchList,
};
