export function __DateString(date: string | Date, pattern = '{Y:4}/{M:2}/{D:2} - {h:2}:{m:2}:{s:2}', locale = 'fa-IR') {
    const value = new Date(date);
    let result = pattern;
    // year
    if (result.includes('{Y:4}')) {
        result = result.split('{Y:4}').join(value.toLocaleDateString(locale, { year: 'numeric' }));
    } else if (result.includes('{Y}')) {
        result = result.split('{Y}').join(value.toLocaleDateString(locale, { year: 'numeric' }));
    } else if (result.includes('{Y:2}')) {
        result = result.split('{Y:2}').join(value.toLocaleDateString(locale, { year: '2-digit' }));
    }
    // month
    if (result.includes('{M}')) {
        result = result.split('{M}').join(value.toLocaleDateString(locale, { month: 'numeric' }));
    } else if (result.includes('{M:2}')) {
        result = result.split('{M:2}').join(value.toLocaleDateString(locale, { month: '2-digit' }));
    } else if (result.includes('{M:S}')) {
        result = result.split('{M:S}').join(value.toLocaleDateString(locale, { month: 'long' }));
    } else if (result.includes('{M:s}')) {
        result = result.split('{M:s}').join(value.toLocaleDateString(locale, { month: 'short' }));
    }
    // day
    if (result.includes('{D}')) {
        result = result.split('{D}').join(value.toLocaleDateString(locale, { day: 'numeric' }));
    } else if (result.includes('{D:2}')) {
        result = result.split('{D:2}').join(value.toLocaleDateString(locale, { day: '2-digit' }));
    }
    // week
    if (result.includes('{W:S}')) {
        result.split('{W:S}').join(value.toLocaleDateString(locale, { weekday: 'long' }));
    } else if (result.includes('{W:s}')) {
        result.split('{W:s}').join(value.toLocaleDateString(locale, { weekday: 'short' }));
    }
    // hour
    if (result.includes('{h}')) {
        result = result.split('{h}').join(value.toLocaleTimeString(locale, { hour: 'numeric' }));
    } else if (result.includes('{h:2}')) {
        result = result.split('{h:2}').join(value.toLocaleTimeString(locale, { hour: '2-digit' }));
    }
    // minute
    if (result.includes('{m}')) {
        result = result.split('{m}').join(value.toLocaleTimeString(locale, { minute: 'numeric' }));
    } else if (result.includes('{m:2}')) {
        result = result.split('{m:2}').join(value.toLocaleTimeString(locale, { minute: '2-digit' }));
    }
    // second
    if (result.includes('{s}')) {
        result.split('{s}').join(value.toLocaleTimeString(locale, { second: 'numeric' }));
    } else if (result.includes('{s:2}')) {
        result.split('{s:2}').join(value.toLocaleTimeString(locale, { second: '2-digit' }));
    }
    return result;
}
