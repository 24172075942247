import { LanguageEnum, ServiceTypeEnum } from 'types';

type routeParam = string | number;
type searchServices = {
    page?: string;
    search?: string;
    order?: string;
    category?: string;
    starGTE?: number;
    starLTR?: number;
};

type searchHousing = {
    rent?: string[];
    min_price?: number;
    max_price?: number;
    min_age?: number;
    max_age?: number;
    host_gender?: string;
    roommate_gender?: string;
    building_type?: string[];
    stay_type?: string[];
    order?: string;
};

type searchEvents = {
    search?: string;
    page?: string;
    category?: string;
    cost?: string;
    capacity?: string;
};

// absolute paths of project pages
export const __PATH = (lang: LanguageEnum) => ({
    home: (service?: ServiceTypeEnum) => `/${lang}${service ? '?service=' + service : ''}`,
    contact: `/${lang}/contact`,
    aboutUs: `/${lang}/about-us`,
    faq: `/${lang}/faq`,
    terms: `/${lang}/terms`,
    login: (redirect?: string) => `/${lang}/login${redirect ? '?redirect=' + redirect : ''}`,
    signup: `/${lang}/signup`,
    search: `/${lang}/search`,
    categories: `/${lang}/categories`,
    blog: {
        index: (searchParams?: { page?: string; search?: string; order?: string }) =>
            `/${lang}/blog/${appendParams(searchParams)}`,
        detail: (slug: string) => `${__PATH(lang).blog.index()}${slug}`,
    },
    brand: (slug: string, searchParams?: searchServices) => {
        const queries = searchParams ? { ...searchParams } : {};
        if (!queries.page?.length || queries.page === '1') delete queries.page;
        if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
        return `/${lang}/offer/${slug}/${appendParams(queries)}`;
    },
    services: {
        index: (searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `/${lang}/offer/${appendParams(queries)}`;
        },
        detail: (brand: string, service: string, searchParams?: searchServices) => {
            const queries = searchParams ? { ...searchParams } : {};
            if (!queries.page?.length || queries.page === '1') delete queries.page;
            if (!queries.order?.length || queries.order === 'most_priority') delete queries.order;
            return `${__PATH(lang).services.index()}${brand}/${service}/${appendParams(queries)}`;
        },
    },
    events: {
        index: (searchParams?: searchEvents) => `/${lang}/events/${appendParams(searchParams)}`,
        detail: (slug: string) => `${__PATH(lang).events.index()}${slug}`,
    },
    panel: {
        index: `/${lang}/panel`,
        profile: () => `${__PATH(lang).panel.index}/profile`,
        orders: () => `${__PATH(lang).panel.index}/orders`,
        ordersDetail: (id: routeParam) => `${__PATH(lang).panel.orders()}/${id}`,
        notification: () => `${__PATH(lang).panel.index}/notification`,
        comments: () => `${__PATH(lang).panel.index}/comments`,
        ticketing: () => `${__PATH(lang).panel.index}/ticketing`,
        mobileMenu: () => `${__PATH(lang).panel.index}/menu-mobile`,
        events: () => `${__PATH(lang).panel.index}/events`,
        myHosts: () => `${__PATH(lang).panel.events()}/mine`,
        myParticipates: () => `${__PATH(lang).panel.events()}/participate`,
        participatesDetail: (id: routeParam) => `${__PATH(lang).panel.myParticipates()}/${id}`,
        createEvent: () => `${__PATH(lang).panel.events()}/new`,
        eventDetail: (id: routeParam) => `${__PATH(lang).panel.myHosts()}/${id}`,
        editEvent: (id: routeParam) => `${__PATH(lang).panel.events()}/${id}/edit`,
        dating: (notification?: '#activate-profile' | '#no-dating-profile' | '#success-payment' | '#fail-payment') =>
            `${__PATH(lang).panel.index}/dating${notification ?? ''}`,
        housing: () => `${__PATH(lang).panel.index}/housing`,
        homeAds: () => `${__PATH(lang).panel.housing()}/houses`,
        host: () => `${__PATH(lang).panel.homeAds()}/host`,
        adForHost: () => `${__PATH(lang).panel.host()}/new`,
        adHostDetail: (id: routeParam) => `${__PATH(lang).panel.host()}/${id}`,
        editAdForHost: (id: routeParam) => `${__PATH(lang).panel.adHostDetail(id)}/edit`,
        house: () => `${__PATH(lang).panel.homeAds()}/house`,
        adForHome: () => `${__PATH(lang).panel.house()}/new`,
        adHouseDetail: (id: routeParam) => `${__PATH(lang).panel.house()}/${id}`,
        editAdForHouse: (id: routeParam) => `${__PATH(lang).panel.adHouseDetail(id)}/edit`,
        roommate: () => `${__PATH(lang).panel.housing()}/roommate`,
        hostRequest: () => `${__PATH(lang).panel.roommate()}/host`,
        askForHost: () => `${__PATH(lang).panel.hostRequest()}/new`,
        editAskForHost: () => `${__PATH(lang).panel.hostRequest()}/edit`,
        houseRequest: () => `${__PATH(lang).panel.roommate()}/house`,
        askForHome: () => `${__PATH(lang).panel.houseRequest()}/new`,
        editAskForHouse: () => `${__PATH(lang).panel.houseRequest()}/edit`,
    },
    verify: {
        success: (session: string) => `/verify/success/?session_id=${session}`,
        fail: `/verify/fail`,
    },
    dating: `/${lang}/dating`,
    orderPrint: `/${lang}/orderPrint`,
    housing: {
        houses: (searchParams?: searchHousing) => `/${lang}/housing/houses/${appendParams(searchParams)}`,
        housesDetail: (id: routeParam) => `${__PATH(lang).housing.houses()}${id}`,
        roommates: (searchParams?: searchHousing) => `/${lang}/housing/roommates/${appendParams(searchParams)}`,
        roommatesDetail: (id: routeParam) => `${__PATH(lang).housing.roommates()}${id}`,
    },
});

const appendParams = (searchParams?: object) =>
    searchParams && Object.keys(searchParams).length
        ? '?' + new URLSearchParams(filterObj(searchParams)).toString()
        : '';

const filterObj = (obj: any) => {
    Object.keys(obj).map((propName) => {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    });
    return obj;
};
