'use client';
import { useState } from 'react';

export function __useAsyncClick(callBack: (e: any) => Promise<any | undefined> | void) {
    const [loading, setLoading] = useState<boolean>(false);

    const onClickHandler = (e: any) => {
        const returned = callBack(e);
        if (returned) {
            setLoading(true);
            returned
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    };

    return { onClick: onClickHandler, loading: loading };
}
