import { DatingProfileType, OrderShowStatusEnum, OrderStatusEnum, OrderType, PersonalInfoType } from 'types';

export const __DetectOrderStatus = (order: OrderType): OrderShowStatusEnum => {
    const status = order.verification_status;
    const doc = order.purchase_documents;
    const rate = order.rate;

    if (status === OrderStatusEnum.pending && (!doc || doc.length === 0)) return OrderShowStatusEnum.not_confirm;
    else if (status === OrderStatusEnum.pending && doc && doc.length > 0) return OrderShowStatusEnum.pending;
    else if (
        [OrderStatusEnum.approved_by_admin, OrderStatusEnum.approved_by_brand].includes(status) &&
        rate !== undefined &&
        rate !== null
    )
        return OrderShowStatusEnum.success;
    else if (status === OrderStatusEnum.approved_by_brand && (rate === undefined || rate === null))
        return OrderShowStatusEnum.success_without_review;
    else if (status === OrderStatusEnum.rejected_by_admin) return OrderShowStatusEnum.fail;
    else return OrderShowStatusEnum.fail;
};

export const __ConvertDatingProfileToMoreInfo = (e?: DatingProfileType): PersonalInfoType => {
    return {
        height: e?.height,
        cooking: e?.cooking,
        job: e?.job,
        job_type: e?.job_type,
        stay: e?.stay,
        sleep: e?.sleep,
        calmness: e?.calmness,
        body_shape: e?.body_shape,
        travel: e?.travel,
        entertainment: e?.entertainment,
    };
};
